import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { DomainsService } from 'src/api/services/DomainsService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { Domain } from 'src/models/domain';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';
import { SuccessAlert } from 'src/theme/sweetAlertWrapper';

interface DomainDialogProps {
  isOpen: boolean;
  existingDomain?: Domain;
  serverIP?: string;
  onClose: (shouldRefresh: boolean) => void;
}

const DomainDialog: FC<DomainDialogProps> = ({
  isOpen = false,
  existingDomain,
  serverIP,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const [host, setHost] = useState<string>('');

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (existingDomain) {
      setHost(existingDomain.host);
    } else {
      setHost('');
    }
    setError('');
  }, [existingDomain]);

  const handleOnDelete = async () => {
    setIsLoading(true);
    try {
      await DomainsService.delete({
        id: existingDomain.id
      });
      onClose(true);
    } catch (e) {
      if (e instanceof ApiException) {
        setError(e.toString());
      }
    }
    setIsLoading(false);
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError('');
    try {
      if (existingDomain) {
        //Edit
        await DomainsService.edit({
          id: existingDomain.id,
          host
        });
        SuccessAlert({
          title: 'Success',
          text: 'Record updated successfully.',
          icon: 'success'
        });
      } else {
        //Create
        await DomainsService.create({
          host
        });
        SuccessAlert({
          title: 'Success',
          text: 'Record added successfully.',
          icon: 'success'
        });
      }
      onClose(true);
    } catch (e) {
      if (e instanceof ApiException) {
        setError(e.toString());
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {existingDomain ? 'Edit Domain' : 'Create Domain'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
          <Grid item xs={1}>
            <TextField
              autoFocus
              label="Host"
              value={host}
              onChange={(e) => {
                setHost(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          {serverIP && (
            <Grid item xs={1}>
              <Typography>
                * Point your domain DNS A record to IP Address: {serverIP}{' '}
              </Typography>
            </Grid>
          )}
        </Grid>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <Divider />
      <DialogActions>
        {existingDomain && (
          <LoadingButton
            color="error"
            loading={isLoading}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
            onClick={handleOnDelete}
          >
            Delete
          </LoadingButton>
        )}
        <Box sx={{ flex: '1 0 0' }} />
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={handleOnSave}
        >
          {existingDomain ? 'Save' : 'Create Domain'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DomainDialog;
