import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import PageHeader from './TemplateHeader';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { TemplatesService } from 'src/api/services/TemplatesService';
import TemplatesTable from './TemplatesTable';
import { Template } from 'src/models/template';
import TemplateDialog from './TemplateDialog';
import NewTemplateIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import TemplateActivationDialog from './TemplateActivationDialog';
import { useAuth } from 'src/providers/AuthProvider';
import { UserIsBankPage } from 'src/models/user';
import TemplateHeader from './TemplateHeader';

export interface TemplateSearchTerms extends Partial<Template> {
  name?: string
}

function ApplicationsTemplates() {
  const { user } = useAuth();
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Template>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Template>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)

  const onSearch = async (terms:Partial<Template>) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Template>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await TemplatesService.search({
        ...searchTerms,
        category: UserIsBankPage(user) ? 'page' : 'offer'
      }, paginatedState)
      setPaginatedResponse(response)
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false)
  const [existingTemplate, setExistingTemplate] = useState<Template>()
  const [isRefreshingTemplate, setIsRefreshingTemplate] = useState(false)

  const onNewTemplate = () => {
    setExistingTemplate(undefined)
    setIsTemplateDialogOpen(true)
  }

  const onEditTemplate = (template:Template) => {
    setExistingTemplate(template)
    setIsTemplateDialogOpen(true)
  }

  const onTemplateDialogClose = (shouldRefresh:boolean) => {
    setIsTemplateDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const [isTemplateActivationDialogOpen, setIsTemplateActivationDialogOpen] = useState(false)

  const onActivateTemplate = (template:Template) => {
    setExistingTemplate(template)
    setIsTemplateActivationDialogOpen(true)
  }

  const onDeactivateTemplate = (template:Template) => {
    setExistingTemplate(template)
    setIsTemplateActivationDialogOpen(true)
  }

  const onTemplateActivationDialogClose = (shouldRefresh:boolean) => {
    setIsTemplateActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Templates</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <TemplateHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear}
            />
          </Grid>
          <Grid item xs={12}>
            <TemplatesTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditTemplate={onEditTemplate}
              onActivateTemplate={onActivateTemplate}
              onDeactivateTemplate={onDeactivateTemplate}
            />
          </Grid>
        </Grid>
      </Container>
      <TemplateActivationDialog 
        isOpen={isTemplateActivationDialogOpen} 
        onClose={onTemplateActivationDialogClose} 
        existingTemplate={existingTemplate} 
      />
      <TemplateDialog isOpen={isTemplateDialogOpen} onClose={onTemplateDialogClose} existingTemplate={existingTemplate} />
    </>
  );
}

export default ApplicationsTemplates;
