import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PagesService } from 'src/api/services/PagesService';
import PagesTable from './PagesTable';
import { Page } from 'src/models/page';
import PageDialog from './PageDialog';
import NewPageIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import PageActivationDialog from './PageActivationDialog';
import { Domain } from 'src/models/domain';
import { Template } from 'src/models/template';
import { DomainsService } from 'src/api/services/DomainsService';
import { TemplatesService } from 'src/api/services/TemplatesService';
import { AppsService } from 'src/api/services/AppsService';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import { Product } from 'src/models/product';
import { ProductsService } from 'src/api/services/ProductsService';
import { Corporation } from 'src/models/corporation';
import { Term } from 'src/models/term';
import { CorporationsService } from 'src/api/services/CorporationsService';
import { TermsService } from 'src/api/services/TermsService';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import PageHeader from './PageHeader';

export interface PageSearchTerms extends Partial<Page> {
  corporation_name:string, 
}

function ApplicationsPages() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Page>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<PageSearchTerms>({
    name: '',
    corporation_name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [domains, setDomains] = useState<Domain[]>([])
  const [templates, setTemplates] = useState<Template[]>([])
  const [corporations, setCorporations] = useState<Corporation[]>([])
  const [terms, setTerms] = useState<Term[]>([])

  const onSearch = async (terms:PageSearchTerms) => {
    console.log(terms)
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:PageSearchTerms) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await PagesService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
      if(domains.length <= 0){
        const d = await DomainsService.getAll();

        const domaindata = await DomainsService.getVacent();
        console.log(domaindata,"<<<======")
        setDomains(domaindata)
      }
      if(templates.length <= 0){
        const t = await TemplatesService.getAll('page');
        setTemplates(t)
      }
      if(corporations.length <= 0){
        const c = await CorporationsService.getAll();
        setCorporations(c)
      }
      if(terms.length <= 0){
        const c = await TermsService.getAll();
        setTerms(c)
      }
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isPageDialogOpen, setIsPageDialogOpen] = useState(false)
  const [existingPage, setExistingPage] = useState<Page>()

  const onNewPage = () => {
    setExistingPage(undefined)
    setIsPageDialogOpen(true)
  }

  const onEditPage = async (page:Page) => {
    const domaindata = await DomainsService.getVacent();
    domaindata?.push(page?.domain)
    setDomains(domaindata || []);
    setExistingPage(page)
    setIsPageDialogOpen(true)
  }

  const onPageDialogClose = (shouldRefresh:boolean) => {
    setIsPageDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const [isPageActivationDialogOpen, setIsPageActivationDialogOpen] = useState(false)

  const onActivatePage = (page:Page) => {
    setExistingPage(page)
    setIsPageActivationDialogOpen(true)
  }

  const onDeactivatePage = (page:Page) => {
    setExistingPage(page)
    setIsPageActivationDialogOpen(true)
  }

  const onPageActivationDialogClose = (shouldRefresh:boolean) => {
    setIsPageActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Pages</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewPage={onNewPage} 
            />}
          </Grid>
          <Grid item xs={12}>
            <PagesTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditPage={onEditPage}
              onActivatePage={onActivatePage}
              onDeactivatePage={onDeactivatePage}
            />
          </Grid>
        </Grid>
      </Container>
      <PageActivationDialog 
        isOpen={isPageActivationDialogOpen} 
        onClose={onPageActivationDialogClose} 
        existingPage={existingPage} 
      />
      <PageDialog 
        isOpen={isPageDialogOpen} 
        onClose={onPageDialogClose} 
        existingPage={existingPage} 
        domains={domains} 
        templates={templates} 
        corporations={corporations} 
        terms={terms} 
      />
    </>
  );
}

export default ApplicationsPages;
