import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { useAuth } from './providers/AuthProvider';
import Login from './content/login';
import ApplicationsCustomers from './content/applications/Customers';
import ApplicationsTransactions from './content/applications/Transactions';
import CustomerDetails from './content/applications/Customers/CustomerDetails';
import ApplicationsCampaigns from './content/applications/Campaigns';
import ApplicationsUser from './content/applications/Users';
import ApplicationsDeclineProfiles from './content/applications/DeclineProfiles';
import ApplicationsApps from './content/applications/Apps';
import ApplicationsDomains from './content/applications/Domains';
import ApplicationsOffers from './content/applications/Offers';
import ApplicationsTemplates from './content/applications/Templates';
import ApplicationsPixels from './content/applications/Pixels';
import FailedRequests from './content/applications/Apps/FailedRequests';
import ApplicationsCorporations from './content/applications/Corporations';
import ApplicationsTerms from './content/applications/Terms';
import ApplicationsProducts from './content/applications/Products';
import ApplicationsPages from './content/applications/Pages';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Main = Loader(lazy(() => import('src/content/applications/Dashboard')));
const Settings = Loader(lazy(() => import('src/content/applications/Settings')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'login',
        element: <Navigate to="/" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Main />,
      }
    ]  
  },
  // {
  //   path: 'customers',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <ApplicationsCustomers />,
  //     },
  //     {
  //       path: ':id',
  //       element: <CustomerDetails />
  //     }
  //   ]  
  // },
  // {
  //   path: 'transactions',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <ApplicationsTransactions />,
  //     },
  //   ]  
  // },
  {
    path: 'products',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsCampaigns />,
      }
    ]  
  },
  // {
  //   path: 'decline-profiles',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <ApplicationsDeclineProfiles />,
  //     }
  //   ]  
  // },
  {
    path: 'users',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsUser />,
      }
    ]  
  },
  {
    path: 'apps',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsApps />,
      },
      {
        path: 'failed-requests/:id',
        element: <FailedRequests />
      }
    ]  
  },
  {
    path: 'domains',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsDomains />,
      }
    ]  
  },
  {
    path: 'pixels',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsPixels />,
      }
    ]  
  },
  {
    path: 'templates',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsTemplates />,
      }
    ]  
  },
  {
    path: 'offers',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsOffers />,
      }
    ]  
  },
  {
    path: 'settings',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Settings />,
      }
    ]  
  },
  {
    path: 'pages',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsPages />,
      }
    ]  
  },
  {
    path: 'corporations',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsCorporations />,
      }
    ]  
  },
  {
    path: 'terms',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsTerms />,
      }
    ]  
  },
  {
    path: 'page-products',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsProducts />,
      }
    ]  
  },
];

export default routes;
