import { Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import NewCampaignIcon from '@mui/icons-material/AddCircle';
import { InternalCampaign } from 'src/models/internalCampaign';
import GridFormInput from 'src/components/GridFormInput';
import { Box } from '@mui/system';
import { Corporation } from 'src/models/corporation';
import { CorporationSearchTerms } from '.';

function CorporationHeader({
  searchTerms, 
  isSearching,
  onSearch,
  onClear,
  onNewCorporation,
}:{
  searchTerms:CorporationSearchTerms, 
  isSearching:boolean,
  onSearch:Function,
  onClear:Function,
  onNewCorporation:Function,
}) {
  const defaultValues = {
    name: '',
    owner: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<CorporationSearchTerms> = async (values: CorporationSearchTerms) => {
    onSearch(methods.control._formValues)
  };

  return (
    <>
        <FormProvider {...methods} >
          <Card>
          <form onSubmit={methods.handleSubmit(onSubmitHandler)} >
          <CardContent>
      <Grid container spacing={1} columns={{ xs: 1, md: 3 }}>
        <GridFormInput
            label='Name'
            name='name'
          />
        <GridFormInput
            label='Owner'
            name='owner'
          />
      </Grid>
      </CardContent>
      <Divider />
        <CardActions>
        <Box display="flex" justifyContent="space-between" width="100%" >
          <Box>
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewCampaignIcon />}
              variant='contained'
              onClick={() => {
                onNewCorporation()
              }}
            >New Corporation</LoadingButton>
          </Box>
          <Box>
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<SearchIcon />}
              variant='outlined'
              type='submit'
              sx={{mr:1}}
            >Show Results</LoadingButton>
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<CancelIcon />}
              variant='outlined'
              color='error'
              onClick={() => {
                methods.reset()
                onClear(methods.control._formValues)
              }}
            >Clear</LoadingButton>
          </Box>
        </Box>
        </CardActions>
      </form>
      </Card>
      </FormProvider>
    </>
  );
}

export default CorporationHeader;