import { Domain } from "src/models/domain";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";
import { PaginatedResponse } from "src/models/paginatedResponse";

export class DomainsService {
  static async getAll():Promise<Domain[]> {
    try {
      const { data } = await Api.client.get<Domain[]>(`domains`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async getVacent():Promise<Domain[]> {
    try {
      const { data } = await Api.client.get<Domain[]>(`vacent-domains`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    host = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Domain>> {
    var params = {
      host,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = 'domains/search';
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Domain>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(domain:Partial<Domain>):Promise<Domain> {
    try {
      const { data } = await Api.client.post('domains', {
        host:domain.host, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(domain:Partial<Domain>):Promise<Domain> {
    try {
      const { data } = await Api.client.post('domains/edit', {
        id:domain.id, 
        host:domain.host, 
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(domain:Partial<Domain>):Promise<void> {
    try {
      await Api.client.delete(`domains/${domain.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(domain:Partial<Domain>):Promise<Domain> {
    try {
      const { data } = await Api.client.get(`domains/${domain.id}/refresh`);
      return data.domain
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(domainID:number):Promise<Domain> {
    try {
      const { data } = await Api.client.post(`domains/${domainID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(domainID:number):Promise<Domain> {
    try {
      const { data } = await Api.client.post(`domains/${domainID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async ip():Promise<string> {
    try {
      const { data } = await Api.client.get(`domains/ip`);
      return data.ip;
    } catch (e) {
      throw e;
    }
  }
  
}