import Swal from 'sweetalert2';

export const SuccessAlert = (options) => {
  const defaultOptions = {
    title: 'Alert',
    text: 'Data saved successfully',
    icon: 'success',
    confirmButtonText: 'OK'
  };

  // Merge default options with user-provided options
  const alertOptions = { ...defaultOptions, ...options };
  return Swal.fire(alertOptions);
};

export const ErrorAlert = (options) => {
  const defaultOptions = {
    title: 'Alert',
    text: 'Opps Internal error',
    icon: 'error',
    confirmButtonText: 'OK'
  };

  // Merge default options with user-provided options
  const alertOptions = { ...defaultOptions, ...options };
  return Swal.fire(alertOptions);
};

export const WarningAlert = (options) => {
  const defaultOptions = {
    title: 'Alert',
    text: 'Something went wrong.',
    icon: 'warning',
    confirmButtonText: 'OK'
  };

  // Merge default options with user-provided options
  const alertOptions = { ...defaultOptions, ...options };
  return Swal.fire(alertOptions);
};
