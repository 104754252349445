import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { TermsService } from 'src/api/services/TermsService';
import TermsTable from './TermsTable';
import { Term } from 'src/models/term';
import TermDialog from './TermDialog';
import { DeclineCode } from 'src/models/declineCode';
import NewTermIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import TermHeader from './TermHeader';

export interface TermSearchTerms extends Partial<Term> {
  name?: string
}

function ApplicationsTerms() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Term>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Term>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [serverIP, setServerIP] = useState<string>()

  const onSearch = async (terms:Partial<Term>) => {
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Term>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await TermsService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isTermDialogOpen, setIsTermDialogOpen] = useState(false)
  const [existingTerm, setExistingTerm] = useState<Term>()
  const [isRefreshingTerm, setIsRefreshingTerm] = useState(false)

  const onNewTerm = () => {
    setExistingTerm(undefined)
    setIsTermDialogOpen(true)
  }

  const onEditTerm = (term:Term) => {
    setExistingTerm(term)
    setIsTermDialogOpen(true)
  }

  const onTermDialogClose = (shouldRefresh:boolean) => {
    setIsTermDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const onRefreshTerm = async (term:Term) => {
    setIsRefreshingTerm(true)
    const refreshedTerm = await TermsService.refresh(term)
    if(refreshedTerm){
      await refreshData()
    }
    setIsRefreshingTerm(false)
  }

  const [isTermActivationDialogOpen, setIsTermActivationDialogOpen] = useState(false)

  const onActivateTerm = (term:Term) => {
    setExistingTerm(term)
    setIsTermActivationDialogOpen(true)
  }

  const onDeactivateTerm = (term:Term) => {
    setExistingTerm(term)
    setIsTermActivationDialogOpen(true)
  }

  const onTermActivationDialogClose = (shouldRefresh:boolean) => {
    setIsTermActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Terms</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            <TermHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewTerm={onNewTerm} 
            />
            {/* <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewTermIcon />}
              variant='contained'
              onClick={() => {
                onNewTerm()
              }}
            >New Term</LoadingButton> */}
          </Grid>
          <Grid item xs={12}>
            <TermsTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditTerm={onEditTerm}
              onActivateTerm={onActivateTerm}
              onDeactivateTerm={onDeactivateTerm}
              onRefreshTerm={onRefreshTerm}
              isRefreshingTerm={isRefreshingTerm} 
            />
          </Grid>
        </Grid>
      </Container>
      <TermDialog 
        isOpen={isTermDialogOpen} 
        onClose={onTermDialogClose} 
        existingTerm={existingTerm} 
        serverIP={serverIP}
      />
    </>
  );
}

export default ApplicationsTerms;
