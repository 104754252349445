import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { CorporationsService } from 'src/api/services/CorporationsService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { Corporation } from 'src/models/corporation';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';
import { SuccessAlert } from 'src/theme/sweetAlertWrapper';

interface CorporationDialogProps {
  isOpen: boolean;
  existingCorporation?: Corporation;
  serverIP?: string;
  onClose: (shouldRefresh: boolean) => void;
}

const CorporationDialog: FC<CorporationDialogProps> = ({
  isOpen = false,
  existingCorporation,
  serverIP,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const [name, setName] = useState<string>('');
  const [owner, setOwner] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (existingCorporation) {
      setName(existingCorporation.name);
      setOwner(existingCorporation.owner);
      setAddress(existingCorporation.address);
    } else {
      setName('');
      setOwner('');
      setAddress('');
    }
    setError('');
  }, [existingCorporation]);

  const handleOnDelete = async () => {
    setIsLoading(true);
    try {
      await CorporationsService.delete({
        id: existingCorporation.id
      });
      onClose(true);
    } catch (e) {
      if (e instanceof ApiException) {
        setError(e.toString());
      }
    }
    setIsLoading(false);
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError('');
    try {
      if (existingCorporation) {
        //Edit
        await CorporationsService.edit({
          id: existingCorporation.id,
          name,
          owner,
          address
        });
        SuccessAlert({
          title: 'Success',
          text: 'Record updated successfully.',
          icon: 'success'
        });
      } else {
        //Create
        await CorporationsService.create({
          name,
          owner,
          address
        });
      }
      SuccessAlert({
        title: 'Success',
        text: 'Record added successfully.',
        icon: 'success'
      });
      onClose(true);
    } catch (e) {
      if (e instanceof ApiException) {
        setError(e.toString());
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {existingCorporation ? 'Edit Corporation' : 'Create Corporation'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
          <Grid item xs={1}>
            <TextField
              autoFocus
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              label="Owner"
              value={owner}
              onChange={(e) => {
                setOwner(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              label="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <Divider />
      <DialogActions>
        {existingCorporation && (
          <LoadingButton
            color="error"
            loading={isLoading}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
            onClick={handleOnDelete}
          >
            Delete
          </LoadingButton>
        )}
        <Box sx={{ flex: '1 0 0' }} />
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={handleOnSave}
        >
          {existingCorporation ? 'Save' : 'Create Corporation'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CorporationDialog;
