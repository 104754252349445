interface ApiError {
  message: string;
  errors: any;
  error: any;
}

interface LaravelError {
  exception: string;
  file: string;
  line: number;
  message: string;
  trace: any;
}

export function isApiError(arg: any): arg is ApiError {
  return (
    arg &&
    ((arg.errors && arg.message && typeof arg.message == 'string') ||
      (arg.error && typeof arg.error == 'string'))
  );
}

export function isLaravelError(arg: any): arg is LaravelError {
  if (!arg) {
    return false;
  }
  if (!arg.trace) {
    return false;
  }
  if (!arg.line) {
    return false;
  }
  if (!arg.exception || typeof arg.exception != 'string') {
    return false;
  }
  if (!arg.file || typeof arg.file != 'string') {
    return false;
  }
  if (typeof arg.message === 'undefined' || typeof arg.message != 'string') {
    return false;
  }
  return true;
}

export class ApiException extends Error {
  error: ApiError;

  message: string;

  constructor(m: string, e?: ApiError) {
    super(m);
    // Set the prototype explicitly.
    //https://github.com/microsoft/TypeScript-wiki/blob/81fe7b91664de43c02ea209492ec1cea7f3661d0/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ApiException.prototype);
    this.message = m;
    this.error = e;
  }

  toString() {
    if (this.error && this.error.errors) {
      let errors: string[] = [];
      if (typeof this.error.errors == 'string') {
        return this.error.errors;
      } else if (this.error.errors instanceof Object) {
        for (let value of Object.values(this.error.errors)) {
          if (typeof value == 'string') {
            errors.push(value);
          } else if (value instanceof Array) {
            for (const v of value) {
              errors.push(v.toString());
            }
          }
        }
      }
      return errors.join('\n');
    }
    return this.message;
  }
}
