import { FC, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Grid, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { User } from 'src/models/user';
import { UsersService } from 'src/api/services/UsersService';
import { SuccessAlert } from 'src/theme/sweetAlertWrapper';

interface UserDialogProps {
  isOpen: boolean;
  existingUser?: User;
  onClose: (shouldRefresh: boolean) => void;
}

const UserDialog: FC<UserDialogProps> = ({
  isOpen = false,
  existingUser,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('support');

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (existingUser) {
      setName(existingUser.name);
      setEmail(existingUser.email);
      setRole(existingUser.role);
    } else {
      setName('');
      setEmail('');
      setRole('support');
    }
  }, [existingUser]);

  const handleOnSave = async () => {
    setIsLoading(true);
    if (existingUser) {
      //Edit
    } else {
      //Create
      await UsersService.create({
        name,
        email,
        role
      });
      SuccessAlert({
        title: 'Success',
        text: 'User added successfully.',
        icon: 'success'
      });
    }
    setIsLoading(false);
    onClose(true);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{existingUser ? 'Edit User' : 'New User'}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1} columns={{ xs: 1 }}>
          <Grid item xs={1}>
            <TextField
              autoFocus
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              label="Email"
              value={email}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              select
              label="Role"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
              fullWidth
            >
              <MenuItem value={'support'}>Customer Support</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'pages'}>Pages Admin</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={handleOnSave}
          color="success"
        >
          {existingUser ? 'Save' : 'Create User'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
