import { Box, Container, Card, TextField, CardContent, Grid, Typography, FormControlLabel, Checkbox, Link as MuiLink, Stack, Alert, AlertTitle} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from 'src/components/FormInput';
import { LoadingButton } from '@mui/lab';
import { literal, object, string, TypeOf, ZodUndefined } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'src/providers/AuthProvider';
import { useState } from 'react';
import { ApiException } from 'src/models/apiError';

// ? Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// ? Login Schema with Zod
const loginSchema = object({
  email: string().min(1,'Email is required').email('Email is invalid'),
  password: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  persistUser: literal(true).optional(),
});

type ILogin = TypeOf<typeof loginSchema>;

function Login() {
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState<string>()

  const defaultValues: ILogin = {
    email: '',
    password: '',
  };

  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ILogin> = async (values: ILogin) => {
    if(!values.email || !values.password) return 
    setIsLoading(true)
    setLoginError(undefined)
    try{
      await login(values)
    } catch (e) {
      if(e instanceof ApiException){
        setLoginError(e.toString())
      }
    }
    setIsLoading(false)
  };

  return (
    <>
      <Helmet>
        <title>{window.config.PROJECT_NAME}</title>
      </Helmet>
      <Container sx={{ 
          maxWidth: { sm: '30rem' },
          alignSelf:'center',
          paddingTop: '27px',
          paddingBottom: '27px'
      }} >
        <Card >
        <CardContent>
          <FormProvider {...methods} >
            <Box
              display='flex'
              flexDirection='column'
              component='form'
              noValidate
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <Typography
                variant='h6'
                component='h1'
                sx={{ textAlign: 'center', mb: '1.5rem' }}
              >
                Log into your account
              </Typography>

              <FormInput
                label='Email'
                type='email'
                name='email'
                placeholder='john@doe.com'
                focused
                required
                sx={{mb:2}}
              />
              <FormInput
                type='password'
                label='Password'
                name='password'
                required
                focused
              />

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    aria-label='trust this device checkbox'
                    required
                    //{...methods.register('persistUser')}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{
                      fontSize: '0.rem',
                      fontWeight: 400,
                      color: '#5e5b5d',
                    }}
                  >
                    Remember me
                  </Typography>
                }
              />
              { loginError && <Alert severity="error">
                {loginError}
              </Alert> }
              <LoadingButton
                loading={isLoading}
                type='submit'
                variant='contained'
                sx={{
                  py: '0.8rem',
                  mt: 2,
                  width: '80%',
                  marginInline: 'auto',
                }}
              >
                Login
              </LoadingButton>
            </Box>
            <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
              <Typography sx={{ fontSize: '0.9rem' }}>
                Forgot your{' '}
                <LinkItem to='/forgotPassword'>password?</LinkItem>
              </Typography>
            </Stack>
          </FormProvider>
        </CardContent>
        </Card>
      </Container>
    </>
  );
}

export default Login;
